/**
 * @fileoverview gRPC-Web generated client stub for io.mipt.typeeleven
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.26.1
// source: messenger.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.io = {};
proto.io.mipt = {};
proto.io.mipt.typeeleven = require('./messenger_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.io.mipt.typeeleven.MessengerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.io.mipt.typeeleven.MessengerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.mipt.typeeleven.EmptyRequest,
 *   !proto.io.mipt.typeeleven.ListUsersResponse>}
 */
const methodDescriptor_Messenger_listUsers = new grpc.web.MethodDescriptor(
  '/io.mipt.typeeleven.Messenger/listUsers',
  grpc.web.MethodType.UNARY,
  proto.io.mipt.typeeleven.EmptyRequest,
  proto.io.mipt.typeeleven.ListUsersResponse,
  /**
   * @param {!proto.io.mipt.typeeleven.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.io.mipt.typeeleven.ListUsersResponse.deserializeBinary
);


/**
 * @param {!proto.io.mipt.typeeleven.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.io.mipt.typeeleven.ListUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.mipt.typeeleven.ListUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.mipt.typeeleven.MessengerClient.prototype.listUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/io.mipt.typeeleven.Messenger/listUsers',
      request,
      metadata || {},
      methodDescriptor_Messenger_listUsers,
      callback);
};


/**
 * @param {!proto.io.mipt.typeeleven.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.mipt.typeeleven.ListUsersResponse>}
 *     Promise that resolves to the response
 */
proto.io.mipt.typeeleven.MessengerPromiseClient.prototype.listUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/io.mipt.typeeleven.Messenger/listUsers',
      request,
      metadata || {},
      methodDescriptor_Messenger_listUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.mipt.typeeleven.EmptyRequest,
 *   !proto.io.mipt.typeeleven.ListChatsResponse>}
 */
const methodDescriptor_Messenger_listChats = new grpc.web.MethodDescriptor(
  '/io.mipt.typeeleven.Messenger/listChats',
  grpc.web.MethodType.UNARY,
  proto.io.mipt.typeeleven.EmptyRequest,
  proto.io.mipt.typeeleven.ListChatsResponse,
  /**
   * @param {!proto.io.mipt.typeeleven.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.io.mipt.typeeleven.ListChatsResponse.deserializeBinary
);


/**
 * @param {!proto.io.mipt.typeeleven.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.io.mipt.typeeleven.ListChatsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.mipt.typeeleven.ListChatsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.mipt.typeeleven.MessengerClient.prototype.listChats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/io.mipt.typeeleven.Messenger/listChats',
      request,
      metadata || {},
      methodDescriptor_Messenger_listChats,
      callback);
};


/**
 * @param {!proto.io.mipt.typeeleven.EmptyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.mipt.typeeleven.ListChatsResponse>}
 *     Promise that resolves to the response
 */
proto.io.mipt.typeeleven.MessengerPromiseClient.prototype.listChats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/io.mipt.typeeleven.Messenger/listChats',
      request,
      metadata || {},
      methodDescriptor_Messenger_listChats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.mipt.typeeleven.ListMessagesRequest,
 *   !proto.io.mipt.typeeleven.ListMessagesResponse>}
 */
const methodDescriptor_Messenger_listMessages = new grpc.web.MethodDescriptor(
  '/io.mipt.typeeleven.Messenger/listMessages',
  grpc.web.MethodType.UNARY,
  proto.io.mipt.typeeleven.ListMessagesRequest,
  proto.io.mipt.typeeleven.ListMessagesResponse,
  /**
   * @param {!proto.io.mipt.typeeleven.ListMessagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.io.mipt.typeeleven.ListMessagesResponse.deserializeBinary
);


/**
 * @param {!proto.io.mipt.typeeleven.ListMessagesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.io.mipt.typeeleven.ListMessagesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.mipt.typeeleven.ListMessagesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.mipt.typeeleven.MessengerClient.prototype.listMessages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/io.mipt.typeeleven.Messenger/listMessages',
      request,
      metadata || {},
      methodDescriptor_Messenger_listMessages,
      callback);
};


/**
 * @param {!proto.io.mipt.typeeleven.ListMessagesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.mipt.typeeleven.ListMessagesResponse>}
 *     Promise that resolves to the response
 */
proto.io.mipt.typeeleven.MessengerPromiseClient.prototype.listMessages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/io.mipt.typeeleven.Messenger/listMessages',
      request,
      metadata || {},
      methodDescriptor_Messenger_listMessages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.mipt.typeeleven.NewChatRequest,
 *   !proto.io.mipt.typeeleven.NewChatResponse>}
 */
const methodDescriptor_Messenger_newChat = new grpc.web.MethodDescriptor(
  '/io.mipt.typeeleven.Messenger/newChat',
  grpc.web.MethodType.UNARY,
  proto.io.mipt.typeeleven.NewChatRequest,
  proto.io.mipt.typeeleven.NewChatResponse,
  /**
   * @param {!proto.io.mipt.typeeleven.NewChatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.io.mipt.typeeleven.NewChatResponse.deserializeBinary
);


/**
 * @param {!proto.io.mipt.typeeleven.NewChatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.io.mipt.typeeleven.NewChatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.mipt.typeeleven.NewChatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.mipt.typeeleven.MessengerClient.prototype.newChat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/io.mipt.typeeleven.Messenger/newChat',
      request,
      metadata || {},
      methodDescriptor_Messenger_newChat,
      callback);
};


/**
 * @param {!proto.io.mipt.typeeleven.NewChatRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.mipt.typeeleven.NewChatResponse>}
 *     Promise that resolves to the response
 */
proto.io.mipt.typeeleven.MessengerPromiseClient.prototype.newChat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/io.mipt.typeeleven.Messenger/newChat',
      request,
      metadata || {},
      methodDescriptor_Messenger_newChat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.mipt.typeeleven.EmptyRequest,
 *   !proto.io.mipt.typeeleven.ExchangeResponse>}
 */
const methodDescriptor_Messenger_receiveMessages = new grpc.web.MethodDescriptor(
  '/io.mipt.typeeleven.Messenger/receiveMessages',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.io.mipt.typeeleven.EmptyRequest,
  proto.io.mipt.typeeleven.ExchangeResponse,
  /**
   * @param {!proto.io.mipt.typeeleven.EmptyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.io.mipt.typeeleven.ExchangeResponse.deserializeBinary
);


/**
 * @param {!proto.io.mipt.typeeleven.EmptyRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.io.mipt.typeeleven.ExchangeResponse>}
 *     The XHR Node Readable Stream
 */
proto.io.mipt.typeeleven.MessengerClient.prototype.receiveMessages =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/io.mipt.typeeleven.Messenger/receiveMessages',
      request,
      metadata || {},
      methodDescriptor_Messenger_receiveMessages);
};


/**
 * @param {!proto.io.mipt.typeeleven.EmptyRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.io.mipt.typeeleven.ExchangeResponse>}
 *     The XHR Node Readable Stream
 */
proto.io.mipt.typeeleven.MessengerPromiseClient.prototype.receiveMessages =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/io.mipt.typeeleven.Messenger/receiveMessages',
      request,
      metadata || {},
      methodDescriptor_Messenger_receiveMessages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.io.mipt.typeeleven.SendMessageRequest,
 *   !proto.io.mipt.typeeleven.SendMessageResponse>}
 */
const methodDescriptor_Messenger_sendMessage = new grpc.web.MethodDescriptor(
  '/io.mipt.typeeleven.Messenger/sendMessage',
  grpc.web.MethodType.UNARY,
  proto.io.mipt.typeeleven.SendMessageRequest,
  proto.io.mipt.typeeleven.SendMessageResponse,
  /**
   * @param {!proto.io.mipt.typeeleven.SendMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.io.mipt.typeeleven.SendMessageResponse.deserializeBinary
);


/**
 * @param {!proto.io.mipt.typeeleven.SendMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.io.mipt.typeeleven.SendMessageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.io.mipt.typeeleven.SendMessageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.io.mipt.typeeleven.MessengerClient.prototype.sendMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/io.mipt.typeeleven.Messenger/sendMessage',
      request,
      metadata || {},
      methodDescriptor_Messenger_sendMessage,
      callback);
};


/**
 * @param {!proto.io.mipt.typeeleven.SendMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.io.mipt.typeeleven.SendMessageResponse>}
 *     Promise that resolves to the response
 */
proto.io.mipt.typeeleven.MessengerPromiseClient.prototype.sendMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/io.mipt.typeeleven.Messenger/sendMessage',
      request,
      metadata || {},
      methodDescriptor_Messenger_sendMessage);
};


module.exports = proto.io.mipt.typeeleven;

